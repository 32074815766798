/* LIGHT */
@font-face {
    font-family: 'Lato';
    src: url('./Lato-Light.ttf') format('woff2');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Lato';
    src: url('./Lato-LightItalic.ttf') format('woff2');
    font-weight: 300;
    font-style: italic;
}

/* REGULAR */
@font-face {
    font-family: 'Lato';
    src: url('./Lato-Regular.ttf') format('woff2');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Lato';
    src: url('./Lato-RegularItalic.ttf') format('woff2');
    font-weight: 400;
    font-style: italic;
}

/* BOLD */
@font-face {
    font-family: 'Lato';
    src: url('./Lato-Bold.ttf') format('woff2');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Lato';
    src: url('./Lato-BoldItalic.ttf') format('woff2');
    font-weight: 700;
    font-style: italic;
}

/* BLACK */
@font-face {
    font-family: 'Lato';
    src: url('./Lato-Black.ttf') format('woff2');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Lato';
    src: url('./Lato-BlackItalic.ttf') format('woff2');
    font-weight: 800;
    font-style: italic;
}